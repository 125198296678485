<template>
  <v-container fluid class="pa-6">
    <v-row no-gutters align="center"
      ><v-col cols="12" md="12" align="start"
        ><h2>
          <v-icon large left>mdi-bell-outline</v-icon>
          <span>Notifications</span>
        </h2>
      </v-col></v-row
    >
    <notification-list :unseen="false" :load-more-button="true" />
  </v-container>
</template>
<script>
import NotificationList from "@/components/notifications/NotificationList";
export default {
  name: "Notification",
  data() {
    return {};
  },
  components: {
    NotificationList
  },
  computed: {}
};
</script>
